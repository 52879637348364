<template>
  <div>
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-truck</v-icon>
          GUIAS HIJAS
        </v-toolbar-title>
        <v-divider class="ml-2 mr-0" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <!-- <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            class="mt-5"
          ></v-text-field>
        </v-col> -->

        <v-col cols="2">
          <v-text-field
            class="mt-5"
            v-model="busAwb"
            label="AWB"
            clearable
            @keyup.enter="cargarLista()"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <!-- <v-text-field
            class="mt-5"
            v-model="busCliente"
            label="Cliente"
            clearable
            @keyup.enter="cargarLista()"
          ></v-text-field> -->
          <v-autocomplete
            class="mt-5"
            label="Clientes"
            v-model="busCliente"
            :items="cfinales"
            item-text="CFINAL_NOMBRE"
            item-value="ENTE_ID"
            clearable
            @change="cargarLista()"
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="2">
          <!-- <v-text-field
            class="mt-5"
            v-model="busRem"
            label="Remitente"
            clearable
          ></v-text-field> -->

          <v-autocomplete
            class="mt-5"
            label="Finca / Remitente"
            v-model="busRem"
            :items="fincas"
            item-text="FINCA_NOMBRE"
            item-value="ENTE_ID"
            clearable
            @change="cargarLista()"
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="2">
          <v-select
            class="mt-5"
            v-model="busEstado"
            :items="estados"
            item-text="descripcion"
            item-value="estado_id"
            label="Estado"
            clearable
            @change="cargarLista()"
          ></v-select>
        </v-col>

        <v-spacer></v-spacer>

        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="nuevoHawb()">
          <v-icon>mdi-plus-circle-outline</v-icon> Nueva Guia
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="listaHawb"
        :items-per-page="itemsPerPage"
        :search="search"
        :loading="loadingTable"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:item.opcion="{ item }">
          <v-btn
            title="Editar guia"
            icon
            color="green"
            @click="editarHawb(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-btn
            title="Recibir guia en bodega"
            icon
            color="blue"
            @click="recibirGuia(item)"
          >
            <v-icon>mdi-call-received</v-icon>
          </v-btn>
        </template>
        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>

    <!-- dialogo GUIA HIJA -->
    <v-dialog v-model="dialogHawb" persistent max-width="760px" scrollable>
      <v-card>
        <v-toolbar height="40" color="blue" dark flat fixed>
          <v-icon left class="mt-1">mdi-truck</v-icon>
          <span class="mt-1 headline"
            >HAWB # {{ datosHawb.NUM_GUIA_HIJA }}</span
          >
          <v-divider></v-divider>
          <v-btn @click="dialogHawb = !dialogHawb" icon dark
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-form ref="formHawb">
            <v-row class="mt-4">
              <v-col class="pt-0 pb-0" cols="6">
                <v-text-field
                  readonly
                  :rules="requiredRule"
                  v-model="datosHawb.CREADO_FECHA"
                  label="Fecha"
                >
                </v-text-field>
              </v-col>

              <v-col class="pt-0 pb-0" cols="6">
                <v-autocomplete
                  :rules="requiredRule"
                  v-model="datosHawb.MAWB_ID"
                  label="Guia madre"
                  :items="guiasMadres"
                  item-text="AWB_NUM"
                  item-value="MAWB_ID"
                  return-object
                  @change="cargarDatosMaster()"
                >
                </v-autocomplete>
                <!-- <v-text-field v-model="datosHawb.AWB_NUM" label="NUM GUIA">
                  </v-text-field> -->
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pt-0 pb-0" cols="6">
                <v-autocomplete
                  :rules="requiredRule"
                  v-model="datosHawb.CNEE_ID"
                  label="Consignatario"
                  :items="cnees"
                  item-text="CNE_NOMBRE"
                  item-value="ENTE_ID"
                ></v-autocomplete>
              </v-col>

              <v-col class="pt-0 pb-0" cols="6">
                <v-autocomplete
                  :rules="requiredRule"
                  v-model="datosHawb.SHP_ID"
                  label="Shipper"
                  :items="shippers"
                  item-text="SHP_NOMBRE"
                  item-value="SHP_ID"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pt-0 pb-0" cols="6">
                <v-autocomplete
                  :rules="requiredRule"
                  label="Cliente"
                  v-model="datosHawb.CLIENTE_ID"
                  :items="cfinales"
                  item-text="CFINAL_NOMBRE"
                  item-value="ENTE_ID"
                >
                </v-autocomplete>
                <!-- <v-text-field v-model="datosHawb." label="">
                  </v-text-field> -->
              </v-col>

              <v-col class="pt-0 pb-0" cols="6">
                <v-autocomplete
                  :rules="requiredRule"
                  label="Finca / Remitente"
                  v-model="datosHawb.FINCA_ID"
                  :items="fincas"
                  item-text="FINCA_NOMBRE"
                  item-value="ENTE_ID"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0 pb-0" cols="6">
                <!-- <v-autocomplete
                  :rules="requiredRule"
                  label="Finca / Remitente"
                  v-model="datosHawb.FINCA_ID"
                  :items="fincas"
                  item-text="FINCA_NOMBRE"
                  item-value="ENTE_ID"
                >
                </v-autocomplete> -->
                <v-text-field
                  v-model="datosHawb.NATURE_OF_GOODS"
                  label="Tipo de productos"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  v-if="datosHawb.GHIJA_ID != '' && datosHawb.GHIJA_ID != null"
                  @click="nuevaCaja()"
                  fab
                  small
                  color="green"
                  dark
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table :items="cajasHawb" :headers="headerCajas">
                  <template v-slot:item.opcion="{ item }">
                    <v-btn @click="borrarCaja(item)" small color="red" icon
                      ><v-icon left>mdi-delete</v-icon></v-btn
                    >

                    <v-btn @click="editarCaja(item)" small color="green" icon
                      ><v-icon left>mdi-pencil</v-icon></v-btn
                    >
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col class="text-center">
            <v-btn small color="primary" @click="guardarHawb()">
              <v-icon left>mdi-content-save</v-icon> Guardar
            </v-btn>

            <!-- <v-btn
              color="blue"
              dark
              class="mx-4"
              small
              @click="imprimirRecibo()"
            >
              <v-icon left>mdi-printer</v-icon> Imprimir
            </v-btn> -->

            <v-btn class="mx-8" small @click="dialogHawb = !dialogHawb">
              <v-icon left>mdi-cancel</v-icon> Salir
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialogo cajas -->
    <v-dialog v-model="dialogCajas" width="400">
      <v-card>
        <v-toolbar height="40" flat color="blue" dark>
          <v-toolbar-title>Cajas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialogCajas = !dialogCajas" dark icon
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-form ref="formCaja">
            <v-row>
              <v-col>
                <v-select
                  :rules="requiredRule"
                  v-model="datosCaja.TCAJA_ID"
                  label="Tipo de caja"
                  :items="tiposCaja"
                  item-text="TCAJA_DESC"
                  item-value="TIPO_CAJA_ID"
                ></v-select>
              </v-col>
              <v-col>
                <v-text-field
                  :rules="requiredRule"
                  label="Piezas"
                  v-model="datosCaja.NUM_PIEZAS"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="datosCaja.UNIDAD_ID"
                  label="Unidades"
                  :items="unidades"
                  item-text="descripcion"
                  item-value="unidad_id"
                ></v-select>
              </v-col>
            </v-row>
            <v-row
              ><v-col cols="4">
                <v-text-field
                  :rules="requiredRule"
                  v-model="datosCaja.ANCHO"
                  label="Ancho"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  :rules="requiredRule"
                  v-model="datosCaja.ALTO"
                  label="Alto"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  :rules="requiredRule"
                  v-model="datosCaja.LARGO"
                  label="Largo"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-toolbar>
          <v-col>
            <v-btn small dark color="green" @click="guardarCaja()"
              ><v-icon left>mdi-content-save</v-icon>Guardar</v-btn
            >

            <v-btn
              class="mx-4"
              small
              dark
              color="blue"
              @click="dialogCajas = !dialogCajas"
              ><v-icon left>mdi-cancel</v-icon>Cancelar</v-btn
            >
          </v-col>
        </v-toolbar>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "HawbLista",
  data: () => ({
    headers: [
      { text: "Opciones", value: "opcion" },
      { text: "AWB#", value: "AWB_NUM" },
      // { text: "GUIA ID", value: "GHIJA_ID" },
      { text: "HAWB#", value: "NUM_GUIA_HIJA" },
      { text: "Fecha", value: "CREADO_FECHA" },
      { text: "Cliente final", value: "CF_NOMBRE" },
      { text: "Remitente", value: "FINCA_NOMBRE" },
      { text: "Tipo carga", value: "NATURE_OF_GOODS" },
      { text: "Piezas", value: "NUM_PIEZAS" },
      { text: "Estado", value: "ESTADO_ID" },
    ],

    requiredRule: [(v) => !!v || "El campo es requerido"],
    emailRules: [
      (v) =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Ingrese un Email válido",
    ],
    busCliente: "",
    busEstado: "",
    busRem: "",
    busAwb: "",
    estados: [
      { estado_id: "CREADA", descripcion: "CREADA" },
      { estado_id: "RECIBIDA", descripcion: "RECIBIDA" },
    ],
    listaHawb: [],
    options: {},
    pageCount: 0,
    dialogHawb: false,
    dialogCajas: false,
    search: null,
    incluirInactivos: false,
    unidades: [
      { unidad_id: "cm", descripcion: "Centímetros" },
      { unidad_id: "in", descripcion: "pulgadas" },
    ],
    dialogRemitente: false,
    datosHawb: {
      MAWB_ID: "",
      GHIJA_ID: "",
      CREADO_FECHA: new Date().toISOString().substr(0, 10),
      ESTADO_ID: "",
      CREADO_POR: "",
      ESTADO_ID: "",
      CLIENTE_ID: "",
      FINCA_ID: "",
      SCLIENTE_ID: "",
      NATURE_OF_GOODS: "",
    },

    cajasHawb: [],
    headerCajas: [
      { text: "Opciones", value: "opcion" },
      { text: "ID", value: "GHC_ID" },
      { text: "TIPO CAJA", value: "TCAJA_DESC" },
      { text: "NUM PIEZAS", value: "NUM_PIEZAS" },
      { text: "FBE", value: "NUM_FBE" },
      { text: "ALTO", value: "ALTO" },
      { text: "ANCHO", value: "ANCHO" },
      { text: "LARGO", value: "LARGO", align: "end" },
    ],

    itemsPerPage: 40,
    currentPage: 1,
    cfinales: [],
    cnees: [],
    fincas: [],
    guiasMadres: [],
    shippers: [],
    tiposCaja: [],
    datosCaja: {},

    shippers: [],
  }),
  computed: {
    ...mapState("master", ["loadingTable", "user"]),
    ...mapGetters("access", [""]),
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    cargarDatosForm() {
      this.setLoadingTable(true);
      this.setUrl("api/form-guias-hijas");
      this.requestApi({
        method: "GET",
        data: {
          page: this.currentPage,
          itemsPerPage: this.itemsPerPage,
        },
      })
        .then((res) => {
          //console.log(res);
          this.guiasMadres = res.data.guiasMadres;
          this.fincas = res.data.fincas;
          this.cfinales = res.data.cfinales;
          this.tiposCaja = res.data.tipos_caja;
          this.shippers = res.data.shippers;
          this.cnees = res.data.clientes;
          this.setLoadingTable(false);
        })
        .catch(() => {
          this.setLoadingTable(false);
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    cargarDatosHawb(ghijaId) {
      //this.setLoadingTable(true);
      this.setUrl("api/datos-guia-hija");
      this.requestApi({
        method: "GET",
        data: {
          GHIJA_ID: ghijaId,
        },
      })
        .then((res) => {
          //console.log(res);
          this.datosHawb = res.data.datos_hawb;
          this.cajasHawb = res.data.cajas_hawb;
          //this.setLoadingTable(false);
        })
        .catch(() => {
          //this.setLoadingTable(false);
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    cargarLista() {
      this.setLoadingTable(true);
      this.setUrl("api/lista-guias-hijas");
      this.requestApi({
        method: "GET",
        data: {
          page: this.currentPage,
          itemsPerPage: this.itemsPerPage,
          busAwb: this.busAwb,
          busCliente: this.busCliente,
          busRem: this.busRem,
          busEstado: this.busEstado,
        },
      })
        .then((res) => {
          //console.log(res);
          this.listaHawb = res.data.lista;
          this.pageCount = res.data.pageCount;
          this.setLoadingTable(false);
        })
        .catch(() => {
          this.setLoadingTable(false);
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    nuevoHawb() {
      this.datosHawb = {
        GHIJA_ID: "",
        MAWB_ID: null,
        CREADO_FECHA: new Date().toISOString().substr(0, 10),
      };
      this.cajasHawb = [];
      this.dialogHawb = true;
    },

    editarHawb(item) {
      this.cargarDatosHawb(item.GHIJA_ID);
      this.dialogHawb = true;
    },

    //window.location.target = "_new";
    //window.location.href = "/form_pod/" + podId;

    eliminarHawb(ghijaId) {
      Vue.swal({
        html: "Está seguro de eliminar el pod ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("api/borrar-pod");
          this.requestApi({
            method: "POST",
            data: {
              POD_ID: podId,
            },
          }).then((res) => {
            //console.log(res);
            this.cargarLista();
            this.alertNotification({ param: { html: res.data.msg } });
          });
        }
      });
    },
    guardarHawb() {
      if (!this.$refs.formHawb.validate()) {
        return false;
      }
      if (this.datosHawb.GHIJA_ID == "" || this.datosHawb.GHIJA_ID == null) {
        this.crearHawb();
      } else {
        this.modificarHawb();
      }
    },

    modificarHawb() {
      //console.log(this.datosPod);
      this.setUrl("api/modificar-guia-hija");
      this.requestApi({
        method: "POST",
        data: {
          datosHawb: this.datosHawb,
        },
      })
        .then((res) => {
          this.alertNotification({ param: { html: res.data.msg } });
          this.cargarLista();
        })
        .then(() => {});
    },

    crearHawb() {
      //console.log(this.datosPod);

      this.setUrl("api/crear-guia-hija");
      this.requestApi({
        method: "POST",
        data: {
          datosHawb: this.datosHawb,
        },
      })
        .then((res) => {
          this.alertNotification({ param: { html: res.data.msg } });
          //this.dialogPod = false;
          this.cargarDatosHawb(res.data.GHIJA_ID);
        })
        .then(() => {});
    },

    imprimirRecibo() {
      this.setUrl("api/imprimir-pod");
      this.requestApi({
        method: "POST",
        data: {
          ghija_id: this.datosHawb.GHIJA_ID,
        },
      })
        .then((res) => {
          let a = document.createElement("a");
          a.href = "data:application/pdf;base64," + res.data.datos;
          a.download = res.data.nombreArchivo;
          a.click();
          this.itemsSel = [];
        })
        .then(() => {});
    },

    nuevaCaja() {
      this.datosCaja = { GHIJA_ID: this.datosHawb.GHIJA_ID, UNIDAD_ID: "in" };
      this.dialogCajas = true;
    },
    editarCaja(item) {
      this.datosCaja = item;
      this.dialogCajas = true;
    },
    guardarCaja() {
      if (this.datosCaja.GHC_ID == null || this.datosCaja.GHC_ID == "") {
        this.crearCaja();
      } else {
        this.modificarCaja();
      }
    },
    crearCaja() {
      if (!this.$refs.formCaja.validate()) {
        return false;
      }
      this.setUrl("api/crear-caja-guia-hija");
      this.requestApi({
        method: "POST",
        data: {
          datosCaja: this.datosCaja,
        },
      })
        .then((res) => {
          this.cargarDatosHawb(this.datosHawb.GHIJA_ID);
          this.dialogCajas = false;
        })
        .then(() => {});
    },
    modificarCaja() {
      this.setUrl("api/modificar-caja-guia-hija");
      this.requestApi({
        method: "POST",
        data: {
          datosCaja: this.datosCaja,
        },
      })
        .then((res) => {
          this.cargarDatosHawb(this.datosHawb.GHIJA_ID);
          this.dialogCajas = false;
        })
        .then(() => {});
    },

    borrarCaja(item) {
      Vue.swal({
        html: "Está seguro de eliminar la caja  ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("api/borrar-caja-guia-hija");
          this.requestApi({
            method: "POST",
            data: {
              GHC_ID: item.GHC_ID,
            },
          }).then((res) => {
            console.log(item);
            this.cargarDatosHawb(item.GHIJA_ID);
            this.alertNotification({ param: { html: res.data.msg } });
          });
        }
      });
    },

    recibirGuia(item) {
      console.log(item);

      this.setUrl("api/recibir-guia-hija");
      this.requestApi({
        method: "POST",
        data: {
          GHIJA_ID: item.GHIJA_ID,
        },
      }).then((res) => {
        //console.log(res);
        //this.cargarDatosHawb(item.GHIJA_ID);
        this.cargarLista();
        this.alertNotification({ param: { html: res.data.msg } });
      });
    },

    cargarDatosMaster() {
      console.log(this.datosHawb.MAWB_ID);
      this.datosHawb.CNEE_ID = this.datosHawb.MAWB_ID.CNEE_ID;
      this.datosHawb.MAWB_ID = this.datosHawb.MAWB_ID.MAWB_ID;
    },

    handlePageChange() {
      this.cargarLista();
    },
  },

  mounted() {
    document.title = "Sistema de carga";
    this.setTitleToolbar("Guias hijas locales");
    this.cargarDatosForm();
    this.cargarLista();
  },
};
</script>
